export const chapterDictionary = {
  grade: 'Darsni baholang',
  tasks: 'Vazifalar',
  ratingSubmit: 'Baholash',
  homeworkSenTextArea: 'Fayl biriktiring va izoh qoldiring',
  notVideo: 'Video topilmadi',
  iVideo: '-video:',
  iLessonTime: 'Dars sanasi: ',
  successRatingSubmit: 'Dars baholandi',
  fileCount: 'Fayllar soni: ',
  homeworkDeadlineTime: 'Uyga vazifa muddati ',
  homeworkDeadline: 'Uyga vazifa muddati tugagan',
  examDeadline: 'Imtihon muddati tugagan',
  myHomework: "Mening jo'natmalarim",
  waitingHomework: 'Sizning vazifangiz tekshirilmoqda...',
  teacherChat: "O'qituvchi izohi",
  acceptHomework: 'Vazifa qabul qilindi',
  rejectHomework: 'Vazifa bekor qilindi',
  firstLesson: '1',
  checking: 'Tekshiruvchi:',
  system: 'Tizim',
  dontHaveVideo: 'Video mavjud emas',
  videoUploading: 'Video yuklanmoqda',
  chapters: 'Mavzular',
  score: 'Ball: ',
  iHomeworkDeadline: 'Uyga vazifa muddati: ',
  iExamDeadline: 'Imtihon muddati: ',
  lessonHastRated: 'Dars allaqachon baholangan',
  homeworkGiven: 'Uyga vazifa berilmagan',
  notAllowedToSubmit: 'Qayta topshirish imkoniyati berilmagan',
  errorWhileUploading: (name: string) => `${name} yuklashda muammo yuz berdi. Birozdan so'ng qayta urinib ko'ring`,
  notAllowedForEmptyFile: "Bo'sh faylni yuklash mumkin emas",
  canEdit: 'Vazifani o’qituvchi tekshirishni boshlaguncha o’zgartirishingiz mumkin',
  successfullyEdit: 'Uyga vazifa o`zgartirish buttoni bosildi',
  passed: 'O`tganlar',
  failedExam: 'Yiqilganlar',
  count: ' - nafar',
  studentName: 'O`quvchi ismi',
  ball: 'Ball',
  reason: 'Sababi',
  exams: 'Imtihon',
  homeworkScore: 'Ball',
  hasEdited: 'Tahrirlangan',
  additionalFiles: 'Qo`shimcha materiallar',
}
